/*MEMO: 別ファイルへの移行検討中*/
/*Frappe Ganttnによるスケジュールのスタイル*/

.gantt .grid-background {
  fill: none;
}

.gantt-header {
  position: fixed;
  z-index: 2;
}

.gantt-container .grid-header {
  fill: rgb(248, 249, 250);
}

.gantt .grid-row {
  fill: transparent;
  /*height:40px;*/
  max-height: 100px;
  /* 適切な高さに変更 (実際の内容に合わせて調整が必要) */
  opacity: 1;
  /* 完全に表示 */
}

.gantt-header .grid-row {
  fill: rgb(248, 249, 250);
  /*height:40px;*/
  max-height: 100px;
  /* 適切な高さに変更 (実際の内容に合わせて調整が必要) */
  opacity: 1;
  /* 完全に表示 */
}

.gantt-header .date {
  fill: rgb(248, 249, 250);
}

.gantt-container .row-line {
  stroke: #ced4da;
}

.gantt .tick {
  stroke: #b8afaf;
  stroke-width: 0.2;
}

.gantt .tick.thick {
  stroke-width: 0.4;
}

.gantt .today-highlight {
  fill: rgba(255, 244, 181, 1);
}

.gantt .arrow {
  fill: none;
  stroke: #666;
  stroke-width: 1.4;
}

/* 予定期間のバー全体 */
.gantt-container .bar-group .bar {
  fill: #15aabf;
  user-select: none;
}

.gantt-move-animation {
  /*Bar移動時に毎回Trasnsiitonを行う*/
  transition: all 0.3s ease;
}

/* 
予定期間のバーの進捗部分（左側）
予定期間のバーは塗り分けがないため不要かもしれない。一旦バー全体と同じ色にしておく。
*/
.gantt-container .bar-group .bar-progress {
  fill: #15aabf;
}

.gantt .bar-invalid~.bar-label {
  fill: #555;
}

.gantt-container .bar-label {
  fill: #fff;
  dominant-baseline: central;
  text-anchor: middle;
  font-size: 12px;
  font-weight: lighter;
  /*非表示*/
}

.gantt .bar-wrapper.active .bar-group .bar {
  fill: #a9b5c1;
}

.gantt .bar-wrapper.active .bar-group .bar-progress {
  fill: #8a8aff;
}

.gantt-container .lower-text {
  font-size: 12px;
  text-anchor: middle;
  fill: black;
}

.today-text {
  fill: rgba(249, 115, 22, 1);
  font-size: 10px;
  font-weight: 600;
}

.gantt-container .upper-text {
  font-size: 16px;
  text-anchor: middle;
  fill: black;
}

.gantt-container {
  overflow: scroll;
  font-size: 12px;
}

.gantt-container .popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 0;
  color: #959da5;
  border-radius: 3px;
}

.gantt-container .popup-wrapper .title {
  border-bottom: 3px solid #a3a3ff;
  padding: 10px;
}

.gantt-container .popup-wrapper .subtitle {
  padding: 10px;
  color: #dfe2e5;
}

.gantt-container .popup-wrapper .pointer {
  position: absolute;
  height: 5px;
  margin: 0 0 0 -5px;
  border: 5px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8);
}

.gantt-close {
  /* 最大高さを0に */
  overflow: hidden !important;
  /* 超えた内容を非表示に */
  opacity: 0 !important;
  /* 透明にする */
  transition: all 0.2s ease !important;
  /* トランジションを設定 */
}

.gantt-container .bar-group .bar[data-status='NOT_STARTED'] {
  fill: rgba(21, 170, 191, 0.4);
}

.gantt-container .bar-group .bar[data-status='IN_PROGRESS'] {
  fill: rgba(241, 174, 0, 0.4);
}

.gantt-container .bar-group .bar[data-status='COMPLETED'] {
  fill: rgba(120, 195, 57, 0.4);
}

.actual-bar-group .bar[data-status='NOT_STARTED'] {
  fill: rgba(27, 184, 205, 1);
  stroke: white;
  stroke-width: 1;
}

.actual-bar-group .bar[data-status='IN_PROGRESS'] {
  fill: rgba(255, 135, 24, 1);
  stroke: white;
  stroke-width: 1;
}

.actual-bar-group .bar[data-status='COMPLETED'] {
  fill: rgba(15, 190, 0, 1);
  stroke: white;
  stroke-width: 1;
}

.delay-bar-group .bar {
  fill: rgba(255, 44, 44, 0.6);
}

/*
.remaining-bar-group rect {
  fill: #ffa8a8;
}*/

.total-row-line {
  stroke: black;
}

.hidden-row-line {
  stroke: none;
}

.gantt .bar-invalid {
  fill: transparent !important;
}

.mantine-InputWrapper-required {
  color: #fa5252;
}

/* MEMO インプットのラベルのstyleを上書き */
.mantine-TextInput-label {
  font-weight: 600;
}

.mantine-InputWrapper-label {
  font-weight: 600;
}

#scroll-area .mantine-Accordion-content {
  padding-left: 0px;
}

#scroll-area::-webkit-scrollbar {
  display: none;
}

.taskNote {
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

/* MEMO: バーの色を変更する場合はここを変更する */
/* 進行中or遅延時以外は色を薄く表示する */

/*スケジュール*/

.bar [data-bar-type='schedule'][data-status='notStarted'] {
  fill: #15aabf;
  opacity: 0.3;
}

.bar [data-bar-type='schedule'][data-status='inProgress'] {
  fill: #15aabf;
}

.bar [data-bar-type='schedule'][data-status='completed'] {
  fill: #15aabf;
  opacity: 0.3;
}

.bar [data-bar-type='schedule'][data-status='delay'] {
  fill: #15aabf;
}

/*実際の値*/

.bar [data-bar-type='actual'][data-status='notStarted'] {
  fill: #25262b;
  opacity: 0.3;
}

.bar [data-bar-type='actual'][data-status='inProgress'] {
  fill: #25262b;
}

.bar [data-bar-type='actual'][data-status='completed'] {
  opacity: 0.3;
  fill: #25262b;
}

.bar [data-bar-type='actual'][data-status='delay'] {
  fill: #fa5252;
}

/*残り*/

.bar [data-bar-type='remaining'][data-status='notStarted'] {
  fill: #5c5f66;
  opacity: 0.3;
}

.bar [data-bar-type='remaining'][data-status='inProgress'] {
  fill: #5c5f66;
}

.bar [data-bar-type='remaining'][data-status='completed'] {
  opacity: 0.3;
  fill: #5c5f66;
}

.bar [data-bar-type='remaining'][data-status='delay'] {
  fill: #ffa8a8;
}

/*遅延*/

.bar [data-bar-type='remaining'][data-status='notStarted'] {
  fill: #c92a2a;
  opacity: 0.3;
}

.bar [data-bar-type='remaining'][data-status='inProgress'] {
  fill: #c92a2a;
}

.bar [data-bar-type='remaining'][data-status='completed'] {
  opacity: 0.3;
  fill: #c92a2a;
}

.bar [data-bar-type='remaining'][data-status='delay'] {
  fill: #c92a2a;
}

.font-size-none {
  font-size: 0px;
}

.font-size-base {
  font-size: 14px;
}

/*下部に固定*/
.user-table-footer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background: #f8f9fa;
  height: 100%;
}

.cesium-infoBox {
  display: none !important;
}

.finished-shape-report-table-item {
  background: none;
  border: none;
  border-radius: 0px;
}

.finished-shape-report-table-item .mantine-Accordion-control {
  padding: 0px 8px;
  border: 1px solid #a8a29e;
}

.finished-shape-report-table-item .mantine-Accordion-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
  padding: 8px 0px;
}

.finished-shape-report-table-item .mantine-Accordion-content {
  padding: 0px !important;
}

.finished-shape-report-table-item table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #a8a29e;
  border-top: none;
}

.save-site-accordion .mantine-Accordion-label {
  font-weight: 600;
}

.save-site-accordion .mantine-Accordion-item {
  border-bottom: 1px solid black;
}

#save-site-form .mantine-InputWrapper-root:not(.save-site-input-default) {
  max-width: initial;
}

.mantine-InputWrapper-root-maw-initial {
  max-width: initial;
}

.maxWidthInitial {
  max-width: initial !important;
}

.cesium-baseLayerPicker-dropDown {
  background-color: rgba(255, 255, 255, 0.9) !important;
  padding: 16px !important;
  width: 300px !important;
}

.cesium-baseLayerPicker-sectionTitle {
  color: #000 !important;
  font-family: 'Open Sans' !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.cesium-baseLayerPicker-categoryTitle {
  color: #000 !important;
  font-family: 'Open Sans' !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.cesium-baseLayerPicker-itemLabel {
  color: #000 !important;
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
}

.cesium-navigation-button {
  color: #000 !important;
  font-family: 'Roboto' !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.cesium-navigation-button-selected {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.cesium-navigation-button-unselected {
  background-color: rgba(161, 161, 170, 0.9) !important;
}

.cesium-navigation-help-instructions {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.cesium-navigation-help-details {
  color: #000 !important;
  font-family: 'Roboto' !important;
}

.cesium-navigation-help-pan {
  color: #0093dc !important;
  font-weight: bold;
}

.cesium-navigation-help-zoom {
  color: #0fbe00 !important;
  font-weight: bold;
}

.cesium-navigation-help-rotate {
  color: #ffb800 !important;
}

.cesium-navigation-help-tilt {
  color: #ff93dc !important;
}

.cesium-navigation-button-icon {
  padding: 2px 1px !important;
}

.cesium-toolbar-button {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.as-built-data-list .mantine-Accordion-item {
  background-color: white;
  border-color: #dee2e6;
  height: 56px;
}

.as-built-data-list .mantine-Accordion-item[data-active='true'] {
  height: auto;
}

.as-built-data-list .mantine-Accordion-label {
  color: #78716c;
}

.as-built-data-list .mantine-Accordion-item[data-is-single-line='false'] .mantine-Accordion-label {
  height: auto;
  padding: 8px 0;
}

.upload-data-file-form input[data-disabled='true'] {
  color: black;
}

.upload-data-file-form .mantine-FileInput-input[data-disabled='true'] span {
  color: #78716c !important;
}

.upload-data-file-form label[data-disabled='true'] {
  color: black !important;
}

.mantine-Switch-label {
  font-size: 14px;
  font-weight: 600;
}

.setting-menu .mantine-Radio-label {
  padding-left: 4px;
}

.gantt-container .lower-text-hightlight {
  fill: darkorange;
  cursor: pointer;
  opacity: 0.8;
}

.gantt-container .lower-text-hightlight:hover {
  fill: darkorange;
  cursor: pointer;
  opacity: 1;
}

.gantt .bar-label {
  fill: black;
  font-weight: bold;
}

/** FrappeGanttの枠関連 **/
.gantt .selectable-cross {
  fill: transparent;
  cursor: pointer;
  stroke: black;
  stroke-width: 1;
  stroke-opacity: 0.5;
}

.gantt .selectable-cross-highlight {
  stroke: black;
  stroke-width: 2;
}

.gantt .selectable-cross-close {
  overflow: hidden !important;
  /* 超えた内容を非表示に */
  opacity: 0 !important;
  /* 透明にする */
  transition: all 0.2s ease !important;
  cursor: default;
}

.gantt .holiday-highlight {
  fill: #e4e4e7;
}

.lower-text-selectable {
  text-decoration: underline;
  cursor: pointer;
  fill: #0064be !important;
}

.highlight-date {
  fill: transparent;
  stroke: black;
  stroke-width: 2;
}

.hidden {
  display: none;
}

.mantine-Carousel-control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  opacity: 1;
}

.mantine-Input-input:disabled {
  color: black;
}

.decoration-none {
  text-decoration: none;
}